exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aliados-bancarios-js": () => import("./../../../src/pages/aliados-bancarios.js" /* webpackChunkName: "component---src-pages-aliados-bancarios-js" */),
  "component---src-pages-calificacion-riesgo-js": () => import("./../../../src/pages/calificacion-riesgo.js" /* webpackChunkName: "component---src-pages-calificacion-riesgo-js" */),
  "component---src-pages-compra-cartera-js": () => import("./../../../src/pages/compra-cartera.js" /* webpackChunkName: "component---src-pages-compra-cartera-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-documentacion-legal-js": () => import("./../../../src/pages/documentacion-legal.js" /* webpackChunkName: "component---src-pages-documentacion-legal-js" */),
  "component---src-pages-estados-financieros-js": () => import("./../../../src/pages/estados-financieros.js" /* webpackChunkName: "component---src-pages-estados-financieros-js" */),
  "component---src-pages-factoring-js": () => import("./../../../src/pages/factoring.js" /* webpackChunkName: "component---src-pages-factoring-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-junta-directiva-js": () => import("./../../../src/pages/junta-directiva.js" /* webpackChunkName: "component---src-pages-junta-directiva-js" */),
  "component---src-pages-prestamos-empresas-js": () => import("./../../../src/pages/prestamos-empresas.js" /* webpackChunkName: "component---src-pages-prestamos-empresas-js" */),
  "component---src-pages-prestamos-personales-js": () => import("./../../../src/pages/prestamos-personales.js" /* webpackChunkName: "component---src-pages-prestamos-personales-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */)
}

